import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDKbK-iMwueik2rbWaMDSVxtg9BYWVFUZ0",
  authDomain: "blis-fd8fb.firebaseapp.com",
  projectId: "blis-fd8fb",
  storageBucket: "blis-fd8fb.appspot.com",
  messagingSenderId: "490109531667",
  appId: "1:490109531667:web:4c44d427c87b22a536b1e6",
  measurementId: "G-4KKHNRDD1V"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);

// Export necessary functions and services
export {
  db,
  storage,
  collection,
  auth,
  firebaseApp,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  ref,
  uploadBytes,
  getDownloadURL,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
};
