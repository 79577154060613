/* eslint-disable react/prop-types */
import React from 'react';
import { FaAndroid, FaApple } from 'react-icons/fa';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const OSDistributionChart = ({ osData }) => {
  // Find data for Android and iOS
  const androidData = osData.find(item => item.OS === 'android') || { Impression: 0 };
  const iosData = osData.find(item => item.OS === 'ios') || { Impression: 0 };
  const totalImpressions = androidData.Impression + iosData.Impression;

  // Calculate impression percentage for each OS
  const androidPercentage = ((androidData.Impression / totalImpressions) * 100).toFixed(0);
  const iosPercentage = ((iosData.Impression / totalImpressions) * 100).toFixed(0);

  // Number of icons for each OS based on percentage
  const totalIcons = 100;
  const androidIconCount = Math.round((androidPercentage / 100) * totalIcons);
  const iosIconCount = totalIcons - androidIconCount;

  return (
    <Card style={{ height: '350px', display: 'flex' }}>
      <CardContent>
        <Typography mb={5} variant="h6">OS-wise Impression Distribution</Typography>
        <Grid container alignItems="center" style={{ marginLeft: '20px' }}>
          {/* Icon Grid Representation */}
          <Grid item xs={8}>
            <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap={1}>
              {/* Android Icons */}
              {Array.from({ length: androidIconCount }).map((_, i) => (
                <FaAndroid key={i} size={17} color="#A4C639" />
              ))}
              {/* iOS Icons */}
              {Array.from({ length: iosIconCount }).map((_, i) => (
                <FaApple key={i + androidIconCount} size={17} color="#999999" />
              ))}
            </Box>
          </Grid>

          {/* Percentage Stats */}
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* Android Stat */}
              <Box display="flex" alignItems="center" marginBottom={2}>
                <FaAndroid size={60} color="#A4C639" />
                <Typography variant="h4" style={{ color: '#A4C639', marginLeft: 8 }}>{androidPercentage}%</Typography>
              </Box>
              {/* iOS Stat */}
              <Box display="flex" alignItems="center">
                <FaApple size={60} color="#999999" />
                <Typography variant="h4" style={{ color: '#999999', marginLeft: 8 }}>{iosPercentage}%</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OSDistributionChart;
