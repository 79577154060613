import React from 'react';
import Grid from "@mui/material/Grid";
import CampaignCharts from './Custom/Charts.js';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import TruncatedText from './Custom/ToolTip.js';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Dashboard components
import { useGlobalState } from "../../GlobalStateContext";

function Dashboard() {
  const { globalBrandsData } = useGlobalState();
  
  // Initialize statistics
  let totalImpressions = 0;
  let totalSpends = 0;
  let totalClicks = 0;
  let videoViews = 0; // Random number for Video Views
  let footfallUplift = 0;
  let topPerformingStore = "N/A";
  let topPerformingTimeBand = "N/A";

  // Calculate statistics from globalBrandsData
  if (globalBrandsData.Campaign) {
    globalBrandsData.Campaign.forEach(campaign => {
      if (campaign.Impressions) {
        totalImpressions += campaign.Impressions;
      }
      if (campaign.Spend) {
        totalSpends += campaign.Spend;
      }
      if (campaign.Clicks) {
        totalClicks += campaign.Clicks;
      }
    });
  }
  if (globalBrandsData['Video Performance']) {
    videoViews = globalBrandsData['Video Performance'].reduce((total, video) => total + (video['Video Starts'] || 0), 0);
  }
  
  // Corrected CTR calculation
  const totalCTR = totalImpressions > 0 ? (totalClicks / totalImpressions).toFixed(4) : 0;

  // Footfall Uplift and Top Performing Store/Time Band
  if (globalBrandsData['Performing Hours'] && globalBrandsData['Performing Hours'][0]) {
    footfallUplift = globalBrandsData['Performing Hours'][0].Uplift;
    topPerformingTimeBand = globalBrandsData['Performing Hours'][0]['Top Performing Hours'];
  }
  
  if (globalBrandsData.Store && globalBrandsData.Store[0]) {
    topPerformingStore = globalBrandsData.Store[0]['Store Name'];
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {/* Existing Cards */}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="auto_graph"
                title="Impressions"
                count={totalImpressions}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="attach_money"
                title="Spends"
                count={`${totalSpends.toFixed(2)} USD`} // Format to two decimal places
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="ads_click"
                title="Clicks"
                count={totalClicks}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="auto_awesome"
                title="CTR"
                count={`${(totalCTR * 100).toFixed(2)}%`} // Convert to percentage and format
              />
            </MDBox>
          </Grid>

          {/* New Cards */}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="play_circle"
                title="Video Views"
                count={videoViews}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="elevator"
                title="Footfall Uplift"
                count={`${footfallUplift.toFixed(2)}%`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
  <MDBox mb={1.5}>
    <ComplexStatisticsCard
      color="secondary"
      icon="store"
      title="Top Performing Store"
      count={<TruncatedText text={topPerformingStore} />}
    />
  </MDBox>
</Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="access_time"
                title="Top Performing Time Band"
                count={topPerformingTimeBand}
              />
            </MDBox>
          </Grid>
        </Grid>
        <CampaignCharts />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
