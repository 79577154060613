/* eslint-disable react/prop-types */
import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { FaUser } from 'react-icons/fa';

const AgeDistributionChart = ({ ageData }) => {
  const totalIcons = 10; // Set a fixed number of icons for each row

  // Helper function to select a color based on the age group
  const getColor = (ageGroup) => {
    switch (ageGroup) {
      case '18-24':
        return '#2E7D32'; // Dark green
      case '25-34':
        return '#4CAF50'; // Green
      case '35-44':
        return '#FFB300'; // Yellow-orange
      case '45+':
        return '#FB8C00'; // Orange
      default:
        return '#757575'; // Grey for others
    }
  };

  return (
    <Card style={{ height: 'auto', padding: '20px' }}>
      <CardContent>
        <Typography mb={3} variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
          Age Group-wise Impression Distribution
        </Typography>
        <Grid container spacing={3}>
          {/* Map through each age group to display their distribution */}
          {ageData.map((ageGroup, index) => {
            // Calculate the number of icons to display based on impression percentage
            const impressionPercentage = ((ageGroup.Impression / ageData.reduce((sum, item) => sum + item.Impression, 0)) * 100).toFixed(0);
            const iconCount = Math.round((impressionPercentage / 100) * totalIcons);

            return (
              <Grid item xs={12} key={index} style={{height: "75px"}}>
                <Box display="flex" alignItems="center">
                  {/* Age Label */}
                  <Typography variant="h6" style={{ fontWeight: '600', color: '#666', minWidth: '80px' }}>
                    {ageGroup.Age}
                  </Typography>
                  <Box display="flex" alignItems="center" ml={2}>
                    {/* Icons representing the percentage */}
                    {Array.from({ length: iconCount }).map((_, i) => (
                      <FaUser key={i} size={20} color={getColor(ageGroup.Age)} style={{ marginRight: 4 }} />
                    ))}
                    {/* Empty icons to complete the row */}
                    {Array.from({ length: totalIcons - iconCount }).map((_, i) => (
                      <FaUser key={i + iconCount} size={20} color="#E0E0E0" style={{ marginRight: 4 }} />
                    ))}
                  </Box>
                  {/* Percentage Label */}
                  <Typography variant="body1" style={{ marginLeft: 16, fontWeight: '500', color: '#444' }}>
                    {impressionPercentage}%
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AgeDistributionChart;
