import React from 'react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement } from 'chart.js';
import { useGlobalState } from "../../../GlobalStateContext"; 
import { Card, CardContent, Typography, Grid } from '@mui/material';
import GenderChart from './GenderChart';
import OSCharts from './OSCharts';
import AudienceChart from './AudienceChart';
import AgeChart from './AgeChart';
// Register necessary components for Chart.js
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement);

const CampaignCharts = () => {
  const { globalBrandsData } = useGlobalState();
  const { Campaign = [], OS = [], Weekdays = [], age = [], audience = [], device = [], gender = [] } = globalBrandsData || {};
  const { "Video Performance": videoStats = [] } = globalBrandsData || [];
  const excelEpoch = new Date(1900, 0, 1); // January 1, 1900
const dateLabels = Campaign.map(item => {
  // Adjust for the Excel leap year bug
  const daysSinceEpoch = item.Date - 2; // Subtracting 2 days to account for leap year
  const dateInMillis = excelEpoch.getTime() + (daysSinceEpoch * 24 * 60 * 60 * 1000); // Convert days to milliseconds
  const date = new Date(dateInMillis);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
});

// Overall Data

  const clicksData = Campaign.map(item => item.Clicks || 0);
  const ctrData = Campaign.map(item => (item.CTR * 100).toFixed(2) || 0);
  const spendData = Campaign.map(item => item.Spend || 0);
  const impressionsData = Campaign.map(item => item.Impressions || 0);

  const comboChartData = {
    labels: dateLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: impressionsData,
        type: 'bar',
        backgroundColor: 'rgba(75,192,192,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'CTR (%)',
        data: ctrData,
        type: 'line',
        borderColor: 'rgba(255,99,132,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const comboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'CTR (%)',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };

  const comboSpendChartData = {
    labels: dateLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: impressionsData,
        type: 'bar',
        backgroundColor: 'rgba(255,206,86,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'Spend',
        data: spendData,
        type: 'line',
        borderColor: 'rgba(54,162,235,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const comboSpendChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Spend ($)',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };
  
  const dateWiseData1 = {
    labels: dateLabels,
    datasets: [
     { label: 'Spend ($)', data: spendData, borderColor: 'rgba(153,102,255,1)', fill: false },
       ],
  };
  const dateWiseData2 = {
    labels: dateLabels,
    datasets: [
      { label: 'Impressions', data: impressionsData, borderColor: 'rgba(255,159,64,1)', fill: false, },
    ],
  };
  const dateWiseData3 = {
    labels: dateLabels,
    datasets: [
      { label: 'Clicks', data: clicksData, borderColor: 'rgba(255,99,132,1)', fill: false },
       ],
  };
  const dateWiseData4 = {
    labels: dateLabels,
    datasets: [
       { label: 'CTR (%)', data: ctrData, borderColor: 'rgba(75,192,192,1)', fill: false },
    ],
  };

  //################### Audience Performance Data####################

  const audienceLabels = audience.map(item => item['Audience Categories']);
  const audienceClicks = audience.map(item => item.Click || 0);
  const audienceCTR = audience.map(item => item.CTR || 0);
  const audienceImpression = audience.map(item => item.Impression || 0);

  const audienceComboChartData = {
    labels: audienceLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: audienceImpression,
        type: 'bar',
        backgroundColor: 'rgba(102,178,255,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'CTR (%)',
        data: audienceCTR,
        type: 'line',
        borderColor: 'rgba(255,87,51,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const audienceComboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Category',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'CTR (%)',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };

  const audiencePerformanceData = {
    labels: audienceLabels,
    datasets: [
      { label: 'Clicks', data: audienceClicks, borderColor: 'rgba(255,99,132,1)', fill: false, tension:0.4 },
      { label: 'CTR (%)', data: audienceCTR, borderColor: 'rgba(75,192,192,1)', fill: false
      ,  tension:0.4 
      },
      // { label: 'Spend ($)', data: audienceImpression, borderColor: 'rgba(153,102,255,1)', fill: false },
       { label: 'Impressions', data: audienceImpression, borderColor: 'rgba(153,102,255,1)', fill: false ,hidden: true, tension:0.4 },
    ],
  };


  //################# Weekdays performance Data####################

  const weekdayLabels = Weekdays.map(item => item['Weekday Performance']);
  const weekdayVisits = Weekdays.map(item => item.Visits || 0);
  const weekdayImpressions = Weekdays.map(item => item.Impressions || 0);
 
  const weekdaysComboChartData = {
    labels: weekdayLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: weekdayImpressions,
        type: 'bar',
        backgroundColor: 'rgba(255,206,86,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'Visits',
        data: weekdayVisits,
        type: 'line',
        borderColor: 'rgba(54,162,235,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const weekdaysComboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Weekdays',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Visits',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };

  const weekdayPerformanceData = {
    labels: weekdayLabels,
    datasets: [
      {
        label: 'Impressions',
        data: weekdayImpressions,
        borderColor: 'rgba(255,99,132,1)', // Red color for impressions
        backgroundColor: 'rgba(255,99,132,0.2)',
        fill: true,
        tension: 0.4, // smooth the line
        hidden: true
      },
      {
        label: 'Visits',
        data: weekdayVisits,
        borderColor: 'rgba(75,192,192,1)', // Green color for visits
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
        tension: 0.4, // smooth the line
      },
    ],
  };
  // const visitsData = Weekdays.map(item => item.Visits || 0);

  // #################OS Impression Data########################
  const osLabels = OS.map(item => item.OS);
  const osClicks = OS.map(item => item["Click "] || 0);
  const osImpressions = OS.map(item => item.Impression || 0);
  const osCTR = OS.map(item => (item.CTR * 100).toFixed(2) || 0); // Convert CTR to percentage

  const OSComboChartData = {
    labels: osLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: osImpressions,
        type: 'bar',
        backgroundColor: 'rgba(153,102,255,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'CTR (%)',
        data: osCTR,
        type: 'line',
        borderColor: 'rgba(255,205,86,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const OSComboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Category',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'CTR (%)',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };

  const osPerformanceData = {
    labels: osLabels,
    datasets: [
      {
        label: 'Clicks',
        data: osClicks,
        backgroundColor: 'rgba(75,192,192,1)', // Green for clicks
      },
      {
        label: 'Impressions',
        data: osImpressions,
        backgroundColor: 'rgba(153,102,255,1)', // Purple for impressions
      },
      {
        label: 'CTR (%)',
        data: osCTR,
        backgroundColor: 'rgba(255,159,64,1)', // Orange for CTR
      },
    ],
  };
  const deviceLabels = device.map(item => item['Device make']);


// ##################Age Performance Data #####################

 // Prepare data for age group performance chart
  //  const ageLabels = age.map(item => item.Age);
  
  const ageLabels = age.map(item => item.Age);
  const ageClicks = age.map(item => item.Click || 0);
 
   const ageImpressionsData = age.map(item => item.Impression || 0);
   const ageClicksData = age.map(item => item.Click || 0);
   const agectrData = age.map(item => (item.CTR * 100).toFixed(2) || 0); // CTR as percentage
 
   const ageComboChartData = {
    labels: ageLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: ageImpressionsData,
        type: 'bar',
        backgroundColor: 'rgba(255,99,132,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'CTR (%)',
        data: agectrData,
        type: 'line',
        borderColor: 'rgba(153,102,255,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const ageComboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Category',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'CTR (%)',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };

   const agePerformanceData = {
     labels: ageLabels,
     datasets: [
       {
         label: 'Impressions',
         data: ageImpressionsData,
         backgroundColor: 'rgba(75,192,192,1)', // Green
         hidden: true
       },
       {
         label: 'Clicks',
         data: ageClicksData,
         backgroundColor: '#E91E63', // Orange
       },
       {
         label: 'CTR (%)',
         data: agectrData,
         backgroundColor: '#3F51B5', // Blue
       },
     ],
   };



  // ###############Device Performance Data##################

   const deviceClicks = device.map(item => item.Click || 0);
   const deviceCTR = device.map(item => item['CTR %']*100 || 0);
   const deviceImpression = device.map(item => item.Impression || 0);
   const deviceComboChartData = {
    labels: deviceLabels, // Use existing date labels
    datasets: [
      {
        label: 'Impressions',
        data: deviceImpression,
        type: 'bar',
        backgroundColor: 'rgba(255,87,51,0.5)', // Light green bars for CTR
        yAxisID: 'y-axis-1', // Left y-axis for CTR
      },
      {
        label: 'CTR (%)',
        data: deviceCTR,
        type: 'line',
        borderColor: 'rgba(102,178,255,1)', // Red line for clicks
        fill: false,
        yAxisID: 'y-axis-2', // Right y-axis for Clicks
      },
    ],
  };
  
  const deviceComboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Category',
        },
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Impressions',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'CTR (%)',
        },
        grid: {
          drawOnChartArea: false, // Prevents grid lines from overlapping with the left y-axis
        },
      },
    },
  };
   const deviceComparisonData = {
     labels: deviceLabels,
     datasets: [
       {
         label: 'Impressions',
         data: deviceImpression,
         backgroundColor: 'rgba(75,192,192,1)', // Green
         hidden: true
       },
       {
         label: 'Clicks',
         data: deviceClicks,
         backgroundColor: '#E91E63', // Orange
       },
       {
         label: 'CTR (%)',
         data: deviceCTR,
         backgroundColor: '#3F51B5', // Blue
       },
     ],
   };

// ####################Video Performance Data#####################

   const dateVideoLabels = videoStats.map(item => {
    // Adjust for the Excel leap year bug
    const daysSinceEpoch = item.Date - 2; // Subtracting 2 days to account for leap year
    const dateInMillis = excelEpoch.getTime() + (daysSinceEpoch * 24 * 60 * 60 * 1000); // Convert days to milliseconds
    const date = new Date(dateInMillis);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  });
  
    const videoStart = videoStats.map(item => item['Video Starts'] || 0);
    const videoFinish = videoStats.map(item => (item['Video Completions']) || 0);
    // const spendVideoData = Campaign.map(item => item.Spend || 0);
    const videoRate = videoStats.map(item => item['Video Completion rate'] || 0);
  
    const videoData1 = {
      labels: dateLabels,
      datasets: [
        { label: 'Video Starts', data: videoStart, borderColor: 'rgba(255,99,132,1)', fill: false
        , hidden: false  
        },],
    };
    const videoData2 = {
      labels: dateLabels,
      datasets: [
        
        { label: 'Video Completions', data: videoFinish, borderColor: 'rgba(75,192,192,1)', fill: false
        ,  hidden: false
        },],
    };
    const videoData3 = {
      labels: dateLabels,
      datasets: [
        { label: 'Video Completion rate', data: videoRate, borderColor: 'rgba(153,102,255,1)', fill: false },
        // { label: 'Impressions', data: impressionsData, borderColor: 'rgba(255,159,64,1)', fill: false,hidden: true },
      ],
    };

// ####################Gender Performance Data############################

    const genderLabels = gender.map(item => item.Gender);
    const genderClicks = gender.map(item => item.Click || 0);
    const genderPerformanceData = {
      labels: genderLabels,
      datasets: [
        { label: 'Clicks', data: genderClicks, backgroundColor: ['#E91E63', '#3F51B5'] },
      ],
    };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
  <Card style={{ height: '400px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">Impressions and CTR</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={comboChartData} options={comboChartOptions} />
      </div>
    </CardContent>
  </Card>
</Grid>
    
      <Grid item xs={12}>
  <Card style={{ height: '400px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">Impressions and Spend ($)</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={comboSpendChartData} options={comboSpendChartOptions} />
      </div>
    </CardContent>
  </Card>

</Grid>
<Grid item xs={12} md={6}>
  <GenderChart genderData={gender} />
</Grid>
      <Grid item xs={12} md={6}>
  <OSCharts osData={OS} />
</Grid>
        {/* <Grid item xs={12} md={6}>
  <AudienceChart audienceData={audience} />
</Grid> */}
      <Grid item xs={12} md={6}>
  <AgeChart ageData={age} />
</Grid>
{/* First Chart (Date-wise Clicks/CTR/Spend/Impressions) taking the entire row */}
      
     
      
<Grid item xs={12} md={6}>
<Card style={{ height: '350px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">Age Group-wise Performance</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={ageComboChartData} options={ageComboChartOptions} />
      </div>
    </CardContent>
  </Card>
        
      </Grid>

      <Grid item xs={12} md={6}>
      <Card style={{ height: '350px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">OS Impressions Comparison</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={OSComboChartData} options={OSComboChartOptions} />
      </div>
    </CardContent>
  </Card>
        {/* <Card style={{ height: '350px', overflow: 'hidden' }}>
          <CardContent>
            <Typography mb={3} variant="h6">OS Impressions Comparison</Typography>
            <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Bar data={osPerformanceData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
            </div>
          </CardContent>
        </Card> */}
      </Grid>

      {/* Additional Charts */}
      <Grid item xs={12} md={6}>
      <Card style={{ height: '350px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">Weekday-wise Impressions & Visits</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={weekdaysComboChartData} options={weekdaysComboChartOptions} />
      </div>
    </CardContent>
  </Card>
       
      </Grid>

      <Grid item xs={12} md={6}>
      <Card style={{ height: '350px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">Audience Performance Data</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={audienceComboChartData} options={audienceComboChartOptions} />
      </div>
    </CardContent>
    </ Card>
      </Grid>


      <Grid item xs={12} md={6}>
      <Card style={{ height: '350px' }}>
    <CardContent style={{ height: '350px', overflow: 'hidden' }}>
      <Typography variant="h6">Top Performing Devices</Typography>
      <div style={{ height: '300px' }}>
        <Bar data={deviceComboChartData} options={deviceComboChartOptions} />
      </div>
    </CardContent>
    </ Card>

       
      </Grid>
      
      {/* <Grid item xs={12} md={6}>
        <Card style={{ height: '350px', overflow: 'hidden' }}>
          <CardContent>
            <Typography mb={2} variant="h6">Top Performing Audience Categories</Typography>
            <div style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Pie data={audiencePerformanceData} options={{ responsive: true, plugins: { legend: { position: 'bottom' } } }} />
            </div>
          </CardContent>
        </Card>
      </Grid> */}

{/*       
      <Grid item xs={12} md={6}>
        <Card style={{ height: '350px', overflow: 'hidden' }}>
          <CardContent>
            <Typography mb={5} variant="h6">Gender-wise Performance</Typography>
            <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Pie data={genderPerformanceData} options={{ responsive: true, plugins: { legend: { position: 'bottom' } } }} />
            </div>
          </CardContent>
        </Card>
      </Grid> */}
            <Grid item xs={12}>
        <Card style={{ height: '400px' }}>
          <CardContent style={{ height: '350px', overflow: 'hidden' }}>
            <Typography variant="h6">Video Starts</Typography>
            <div style={{ height: '300px' }}>
              <Line data={videoData1} options={{ maintainAspectRatio: false }} />
            </div>
          </CardContent>
        </Card>
      </Grid>
            <Grid item xs={12}>
        <Card style={{ height: '400px' }}>
          <CardContent style={{ height: '350px', overflow: 'hidden' }}>
            <Typography variant="h6">Video Clicks</Typography>
            <div style={{ height: '300px' }}>
              <Line data={videoData2} options={{ maintainAspectRatio: false }} />
            </div>
          </CardContent>
        </Card>
      </Grid>
            <Grid item xs={12}>
        <Card style={{ height: '400px' }}>
          <CardContent style={{ height: '350px', overflow: 'hidden' }}>
            <Typography variant="h6">Video Completion Rate</Typography>
            <div style={{ height: '300px' }}>
              <Line data={videoData3} options={{ maintainAspectRatio: false }} />
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
</Grid>
    </Grid>
  );
};

export default CampaignCharts;
