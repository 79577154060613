import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { firebaseApp } from '../../../firebase.js';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

const db = getFirestore(firebaseApp);

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    

    const fetchBrands = async () => {
      try {
        const brandsCollection = collection(db, 'brand_details');
        const brandsSnapshot = await getDocs(brandsCollection);
        const brandsList = brandsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
        setBrands(brandsList);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchUsers();
    fetchBrands();
  }, []);

  const handleAddBrand = async () => {
    if (!selectedUserId || !selectedBrand) return;

    const userRef = doc(db, 'users', selectedUserId);
    const user = users.find(user => user.id === selectedUserId);
    if (!user.brands.includes(selectedBrand)) {
      const updatedBrands = [...user.brands, selectedBrand];
      await updateDoc(userRef, { brands: updatedBrands });
      setUsers(users.map(user => user.id === selectedUserId ? { ...user, brands: updatedBrands } : user));
    }
    setSelectedBrand('');
    setSelectedUserId(null);
  };

  const handleRemoveBrand = async (userId, brand) => {
    const userRef = doc(db, 'users', userId);
    const user = users.find(user => user.id === userId);
    const updatedBrands = user.brands.filter(b => b !== brand);
    await updateDoc(userRef, { brands: updatedBrands });
    setUsers(users.map(user => user.id === userId ? { ...user, brands: updatedBrands } : user));
  };

  const handleDeleteUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await deleteDoc(userRef);
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const openFresh = () => {
    const url = "https://blisdashboard.com/authentication/sign-up";
    // const url = "http://localhost:3000/authentication/sign-up";
    const noCacheUrl = `${url}?t=${new Date().getTime()}`; // Unique query string
    window.open(url, '_blank', 'incognito=yes');
  };

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
                <MDBox display="flex" justifyContent="space-between" alignItems="center">
    <MDTypography variant="h6" color="white">
      User Management
    </MDTypography>
    <MDButton
      variant="gradient"
      color="success"
      size="small"
      onClick={openFresh}
    >
      Add User
    </MDButton>
  </MDBox>
            </MDBox>
            <MDBox p={3}>
              <TableContainer>
                <Table>
                {/* <TableHead >
  <TableRow>
    <TableCell align="left" style={{ fontWeight: 'bold', width: '25%' }}>Name</TableCell>
    <TableCell align="left" style={{ fontWeight: 'bold', width: '25%' }}>Email</TableCell>
    <TableCell align="left" style={{ fontWeight: 'bold', width: '25%' }}>Brands</TableCell>
    <TableCell align="center" style={{ fontWeight: 'bold', width: '25%' }}>Actions</TableCell>
  </TableRow>
</TableHead> */}


                  <TableBody>
                  <TableRow>
    <TableCell align="left" style={{ fontWeight: 'bold', width: '25%' }}>Name</TableCell>
    <TableCell align="left" style={{ fontWeight: 'bold', width: '25%' }}>Email</TableCell>
    <TableCell align="left" style={{ fontWeight: 'bold', width: '25%' }}>Brands</TableCell>
    <TableCell align="center" style={{ fontWeight: 'bold', width: '25%' }}>Actions</TableCell>
  </TableRow>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        
                        
                            <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <MDBox display="flex" flexWrap="wrap" gap={1}>
                            {user.brands.map((brand, index) => (
                              <MDBox
                                key={index}
                                display="flex"
                                alignItems="center"
                                p={0.5}
                                bgcolor="grey.200"
                                borderRadius="4px"
                                mb={0.5}
                              >
                                <MDTypography variant="body2" mr={1}>{brand}</MDTypography>
                                <IconButton
                                  size="small"
                                  onClick={() => handleRemoveBrand(user.id, brand)}
                                >
                                  <DeleteIcon fontSize="small" color="dark" />
                                </IconButton>
                              </MDBox>
                            ))}
                          </MDBox>
                        </TableCell>
                        <TableCell>
                          <TextField
                            select
                            label="Add Brand"
                            fullWidth
                            value={selectedUserId === user.id ? selectedBrand : ''}
                            onChange={(e) => {
                              setSelectedUserId(user.id);
                              setSelectedBrand(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            <MenuItem value="" disabled>Select a brand</MenuItem>
                            {brands.map((brand) => (
                              <MenuItem key={brand.id} value={brand.name}>{brand.name}</MenuItem>
                            ))}
                          </TextField>
                          <MDButton
                            color="success"
                            size="small"
                            onClick={handleAddBrand}
                            disabled={!selectedBrand || selectedUserId !== user.id}
                            sx={{ mt: 1 }}
                          >
                            Add Brand
                          </MDButton>
                         
                        </TableCell>
                        <TableCell> <IconButton
                            color="error"
                            onClick={() => handleDeleteUser(user.id)}
                            sx={{ mt: 1 }}
                            >
                            <DeleteIcon />
                          </IconButton></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default UsersTable;
